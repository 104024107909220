export default {
  "login": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benvenuti al nostro servizio di ricarica"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abiamo l'energia che hai bisogno.<br/><br/> Accedi con le tue credenziali e carica la tua macchina."])},
    "roomNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numbero camera"])},
    "birthday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data di compleanno"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accedi"])}
  },
  "charging": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ricarica attual"])},
    "started": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Processo di ricarica partito"])},
    "start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inizia un nuovo processo di ricarica"])},
    "stop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ferma il processo attuale"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stato"])},
    "currentEnergy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Energia attuale"])},
    "totalEnergy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Energia totale"])},
    "currentStateOfCharge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stato di ricarica"])},
    "noCharging": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun processo di ricarica attuale"])},
    "history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Storico"])}
  },
  "chargingHistory": {
    "totalEnergy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Energia attuale"])},
    "currentStateOfCharge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stato di ricarica"])},
    "duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durata"])},
    "charging": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ricarica"])},
    "start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inizio"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stato"])},
    "SUSPENDED_EVSE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suspended EVSE"])},
    "INITIALIZING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["inizializzazione"])},
    "noHistory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessuna ricarica precedente"])},
    "INIZIALIZING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["inizializzazione"])},
    "SUSPENDED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suspended EVSE"])},
    "NO_INVOICE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Invoice"])},
    "CLOSED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chiuso"])},
    "PREINVOICED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prima del adebito"])},
    "ERRORINVOICED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Errore nel adebito"])},
    "INVOICED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Addebitato"])},
    "FINISHED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terminato"])},
    "RUNNING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In carica"])}
  },
  "duration": {
    "days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["giorni"])},
    "hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ore"])},
    "minutes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["min"])}
  },
  "startcharging": {
    "titlePoint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scegli il tuo punto di ricarica"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inizia la ricarica"])},
    "openend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inizia subito"])},
    "openendDescr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carica quanto vuoi e termina il processo staccando il cavo di alimentazione."])},
    "fixedAmount": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Carica ", _interpolate(_named("amt")), " kwh"])},
    "fixedAmountDescr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scegli quanta energia caricare e il processo termina automaticamente al raggiungimento di questa soglia"])},
    "plugType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plug Type"])},
    "powerClass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Power Class"])},
    "power": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Power"])},
    "titleChargingRunning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Processo iniziato"])},
    "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scegli"])},
    "AVAILABLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disponibile"])},
    "CHARGING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Occupato"])},
    "FINISHING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In Terinazione"])},
    "UNAVAILABLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non disponibile"])},
    "PREPARING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In preparazione"])},
    "ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Errore"])},
    "FAULTED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Errore"])},
    "Faulted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Errore"])},
    "errorActivating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Errore nel inizializzazione della carica"])},
    "chargingStarted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La sua ricarica ha iniziato"])}
  }
}