const conf = {
    TenantUrl: "https://api.ecogy.app/Tenant/",
    LoginUrl: "https://api.ecogy.app/Security/LoginByRoom",
    ChargingDetailUrl: "https://api.ecogy.app/Charging/${id}",
    ChargingHistoryUrl: "https://api.ecogy.app/Charging/chargingHistory",
    ChargingPointsUrl: "https://api.ecogy.app/Charging/points",
    ChargingStartUrl: "https://api.ecogy.app/Charging/${point}/start",
    ChargingStopUrl: "https://api.ecogy.app/Charging/${point}/${id}/stop"
}

const confdev = {
    TenantUrl: "https://api-dev.ecogy.app/Tenant/",
    LoginUrl: "https://api-dev.ecogy.app/Security/LoginByRoom",
    ChargingDetailUrl: "https://api-dev.ecogy.app/Charging/${id}",
    ChargingHistoryUrl: "https://api-dev.ecogy.app/Charging/chargingHistory",
    ChargingPointsUrl: "https://api-dev.ecogy.app/Charging/points",
    ChargingStartUrl: "https://api-dev.ecogy.app/Charging/${point}/start",
    ChargingStopUrl: "https://api-dev.ecogy.app/Charging/${point}/${id}/stop"
}
const localdev = {
    TenantUrl: "http://localhost:5000/Tenant/",
    LoginUrl: "http://localhost:5000/Security/LoginByRoom",
    ChargingDetailUrl: "http://localhost:5000/Charging/${id}",
    ChargingHistoryUrl: "http://localhost:5000/Charging/chargingHistory",
    ChargingPointsUrl: "http://localhost:5000/Charging/points",
    ChargingStartUrl: "http://localhost:5000/Charging/${point}/start",
    ChargingStopUrl: "http://localhost:5000/Charging/${point}/${id}/stop"
}

const getConf = () => {
    if(window.location.host.startsWith("localhost"))
        return localdev;
    if(window.location.host.startsWith("dev.ecogy.app"))
        return confdev;
    return conf;
}

export default getConf;