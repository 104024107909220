import { defineComponent } from 'vue';
import moment from 'moment';
import axios from 'axios';
import config from '@/config';
import { ChargingProcessStatus } from '@/model/chargingProcessStatus';
export default defineComponent({
  name: 'ChargingHistory',
  props: {
    "chargingProcesses": {
      type: Object,
      required: true
    }
  },
  emits: ['refresh'],
  methods: {
    async stopCharge(charProc) {
      await axios.post(config().ChargingStopUrl.replace("${point}", charProc.chargingpoint.id).replace("${id}", charProc.id), {}, {
        headers: {
          Authorization: 'Bearer ' + JSON.parse(sessionStorage.getItem("user"))
        }
      });
      this.refresh();
    },
    statusIsRunning(status) {
      return status == ChargingProcessStatus.RUNNING;
    },
    translateStatus(status) {
      return this.$t('chargingHistory.' + ChargingProcessStatus[status]);
    },
    formatDateTime(value) {
      return moment(value).fromNow();
    },
    getDuration(value) {
      if (!value.endedAt || !value.startedAt) return 0;
      let dur = moment.duration(moment(value.endedAt).diff(moment(value.startedAt)));
      let ret = "";
      if (dur.days() > 0) ret += dur.days() + " " + this.$t("duration.days") + " ";
      if (dur.hours() > 0) ret += dur.hours() + " " + this.$t("duration.hours") + " ";
      if (dur.minutes() > 0) ret += dur.minutes() + " " + this.$t("duration.minutes") + " ";
      return ret;
    }
  }
});