import moment from 'moment';
import { defineComponent } from 'vue';
export default defineComponent({
  name: 'EcogyApp',
  inject: ["TENANT"],
  data() {
    return {
      logoUrl: "https://www.residence-mirabell.com/images/1674806948/t/design/logo-brown.png",
      language: localStorage.getItem("locale") || "en",
      backgroundcolor: '#fff'
    };
  },
  mounted() {
    const tenant = this.TENANT;
    console.log("tenant", tenant);
    if (tenant) {
      this.logoUrl = tenant.logoUrl;
      this.backgroundcolor = tenant.backgroundColor;
    }
  },
  methods: {
    langchange() {
      localStorage.setItem("locale", this.language);
      this.$i18n.locale = this.language;
      moment.locale(this.language);
    }
  }
});