import { createApp } from "vue";
import './element.scss';
import App from "./App.vue";
import router from "./router";
import * as ElementPlusIconsVue from "@element-plus/icons-vue";
import i18n from './i18n'
import axios from "axios";
import { Tenant } from "./model/tenant";
import config from './config';

let host = location.pathname.split('/')[1];
if(!host)
  host = "ecogy";

axios.get<Tenant>(config().TenantUrl + host).then(tenant=>{

  const app = createApp(App);
  for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component);
  }
  app
  .provide("TENANT", tenant.data)
  .use(i18n)
  .use(router)
  .mount("#app"); 

});

