export enum ChargingProcessStatus {
  INIZIALIZING,
  SUSPENDED,
  NO_INVOICE,
  CLOSED,
  PREINVOICED,
  ERRORINVOICED,
  INVOICED,
  FINISHED,
  RUNNING
}
