import { defineComponent } from 'vue';
import 'vue-scroll-picker/lib/style.css';
import { VueScrollPicker } from 'vue-scroll-picker';
import moment from 'moment';
export default defineComponent({
  props: {
    placeholder: String,
    format: String,
    modelValue: Date
  },
  emits: ['update:modelValue'],
  data() {
    return {
      new_currentYear: this.currentYear,
      new_currentMonth: this.currentMonth,
      new_currentDay: this.currentDay,
      dialogVisible: false
    };
  },
  async mounted() {
    this.headerTitle = this.title || this.$t("charging.title");
    this.dotsClass = this.dotsAnnimation ? "titleDots" : "";
    this.chargingProcess_tmp = this.chargingProcess;
  },
  components: {
    VueScrollPicker
  },
  methods: {
    closeDialog: function () {
      this.$emit('update:modelValue', new Date(this.new_currentYear, this.new_currentMonth - 1, this.new_currentDay));
      this.dialogVisible = false;
    },
    openDialog: function () {
      this.new_currentDay = this.modelValue.getDate();
      this.new_currentMonth = this.modelValue.getMonth() + 1;
      this.new_currentYear = this.modelValue.getFullYear();
      this.dialogVisible = true;
    }
  },
  computed: {
    years() {
      const currYear = new Date().getFullYear();
      const lastYear = currYear - 100;
      return Array.from({
        length: currYear - lastYear + 1
      }, (_, index) => lastYear + index).reverse();
    },
    months() {
      return Array.from({
        length: 12
      }, (_, index) => index + 1);
    },
    days() {
      const lastDay = new Date(this.new_currentYear, this.new_currentMonth, 0).getDate();
      return Array.from({
        length: lastDay
      }, (_, index) => index + 1);
    },
    dayLabel() {
      return moment(this.modelValue).format(this.format || "DD.MM.YYYY");
    }
  }
});