export default {
  "login": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Willcommen zu unserem Charging Service"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir habe die Energie die Sie brauchen.<br/><br/> Melden Sie sich mit Ihrer Zimmernummer und Geburtsdatum an und laden Sie Ihr Fahrzeug."])},
    "roomNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zimmernummer"])},
    "birthday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geburtstag"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anmelden"])}
  },
  "charging": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktueller Ladevorgang"])},
    "started": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ladevorgang gestarted"])},
    "start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Starten Sie eien neuen Ladevorgang"])},
    "stop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stoppen Sie den aktuellen Ladevorgang"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
    "currentEnergy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktuelle Energie"])},
    "totalEnergy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Energie insgesamt"])},
    "currentStateOfCharge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ladestatus"])},
    "noCharging": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kein aktueller Ladevorgang"])},
    "history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Historie"])}
  },
  "chargingHistory": {
    "totalEnergy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Energie insgesamt"])},
    "currentStateOfCharge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ladestatus"])},
    "duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeitraum"])},
    "charging": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laden"])},
    "start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
    "SUSPENDED_EVSE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suspended EVSE"])},
    "noHistory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine vorherigen Ladevorgänge"])},
    "INIZIALIZING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Initialisierung"])},
    "SUSPENDED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suspended from Vehicle"])},
    "NO_INVOICE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Abrechnung"])},
    "CLOSED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abgeschlossen"])},
    "PREINVOICED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vor Abrechnung"])},
    "INVOICED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abgerechnet"])},
    "ERRORINVOICED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler in Abrechnung"])},
    "FINISHED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beendet"])},
    "RUNNING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Läuft"])}
  },
  "duration": {
    "days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tage"])},
    "hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["stunden"])},
    "minutes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["min"])}
  },
  "startcharging": {
    "titlePoint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wählen Sie ihren Ladepunkt"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Starte Ladung"])},
    "openend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sofort starten"])},
    "openendDescr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laden Sie so viel Sie möchten und starten Sie den Prozess durch das ausstecken des Kabels."])},
    "fixedAmount": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("amt")), " kwh laden"])},
    "fixedAmountDescr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wählen Sie wieviel Energie Sie laden möchten und der Prozess stopped automatisch wenn dieser Wert erreicht wurde."])},
    "plugType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plug Type"])},
    "powerClass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Power Class"])},
    "power": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Power"])},
    "titleChargingRunning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prozess gestarted"])},
    "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auswählen"])},
    "AVAILABLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verfügbar"])},
    "PREPARING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In Vorbereitung"])},
    "CHARGING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Besetzt"])},
    "FINISHING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beim abschliessen"])},
    "ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler"])},
    "UNAVAILABLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nicht Verfügbar"])},
    "FAULTED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler"])},
    "Faulted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler"])},
    "errorActivating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler beim aktivieren der Ladung"])},
    "chargingStarted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihr Ladung hat begonnen"])}
  }
}